/**
 * Jquery plugin for horizontal slider
 * Initialize: $(".card-slider-container").zoSlider();
 */
(function() {
    $.fn.zoSlider = function(config) {

        // for each item (slider) in selection
        return this.each(function() {
            var container = this;
            var leftBtn = $(container).children('.slider-left')[0];
            var rightBtn = $(container).children('.slider-right')[0];
            var slider = $(container).children('.card-slider')[0];
            var scrollPos = $(slider).scrollLeft();
            var SCROLL_TIME_MS = 400;
            var SCROLL_LENGTH = config && config.SCROLL_LENGTH ? config.SCROLL_LENGTH : 300;
            var innerWidth = $(slider).innerWidth();
            var scrollWidth = $(slider)[0].scrollWidth;

            // start with left button hidden
            $(leftBtn).css({ visibility: 'hidden' });

            // detect end/start of scroll position
            $(slider).scroll(function() {
                $(rightBtn).css({ visibility: 'visible' });
                $(leftBtn).css({ visibility: 'visible' });

                if (Math.ceil($(this).scrollLeft() + innerWidth) >= scrollWidth) {
                    // hide right button
                    $(rightBtn).css({ visibility: 'hidden' });
                } else if ($(this).scrollLeft() === 0) {
                    // hide left button
                    $(leftBtn).css({ visibility: 'hidden' });
                }

                // update scroll position
                scrollPos = $(this).scrollLeft();
            });

            // scroll left on left click
            $(leftBtn).click(function() {
                scrollPos -= SCROLL_LENGTH;
                $(slider).animate({ scrollLeft: scrollPos }, SCROLL_TIME_MS);
            });

            // scroll right on right click
            $(rightBtn).click(function() {
                scrollPos += SCROLL_LENGTH;
                $(slider).animate({ scrollLeft: scrollPos }, SCROLL_TIME_MS);
            });
        });
    }
}(jQuery));