/**
 * Created by kaushal on 12/06/17.
 */

/**
 * Modal function
 * <div id="myModal" class="zo-modal" style="display: none">
 *       <div class="zm-close">
 *           <span>&times;</span>
 *       </div>
 *       <!-- Modal content -->
 *       <div class="zm-content">
 *           <p>test content</p>
 *       </div>
 *   </div>
 */
(function($){

  var ZoModalClass = function($self, options) {
    var defaults = {
      right: '20px',
      top: '8px',
      bottom: 'initial',
      left: 'initial',
      color: "#ffffff",
      cursor: "pointer",
      contentWidth: "90%",
      backgroundColor: "#fefefe",
      border: "1px solid #888",
      height: "90%",
      onOpen: function () {
        //console.log('zo:open')
      },
      onClose: function () {
        //console.log('zo:close')
      }
    };

    this.config = $.extend({}, defaults, options);

    this.modal = $self;
    this.content = this.modal.children('.zm-content')[0];

    // close button
    this.closeBtn = this.modal.children('.zm-close')[0];
    this.closeBtn.addEventListener("click", this.close.bind(this));
    this.modal[0].addEventListener("click", this.close.bind(this));

    // content
    this.content = this.modal.children('.zm-content')[0];

    // Apply styling
    this.modal.css({
      display: "none",
      position: "fixed",
      zIndex: 9999,
      paddingTop: "80px",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      overflow: "auto",
      backgroundColor: "rgb(0,0,0)",
      backgroundColor: "rgba(0,0,0,0.8)"
    });

    $(this.content).css({
      backgroundColor: this.config.backgroundColor,
      margin: "auto",
      padding: "20px",
      border: this.config.border,
      width: this.config.contentWidth,
      height: this.config.height
    });

    $(this.closeBtn).css({
      position: "fixed",
      right: this.config.right,
      top: this.config.top,
      bottom: this.config.bottom,
      left: this.config.left,
      color: this.config.color,
      cursor: this.config.cursor,
      float: "right",
      fontSize: "40px",
    });


  };

  ZoModalClass.prototype.open = function() {
    this.modal.css({"display": "block"});
    this.config.onOpen();
  };

  ZoModalClass.prototype.close = function(ev) {
    // do not close if clicked on content div
    if ((ev.target === this.content)||($.contains(this.content, ev.target))) {
      return false;
    }

    this.modal.css({"display": "none"});
    this.config.onClose();
  };


  $.fn.ZoModal = function(option) {
    var options = typeof option === "object" && option;
    var $plugin = this.data("plugin");

    // initialize plugin
    if (!$plugin) {
      $plugin = new ZoModalClass(this, options);
      this.data("plugin", $plugin);
    }

    // call method if requested
    if (typeof option === 'string') {
      $plugin[option]();
    }

  };
}(jQuery));
