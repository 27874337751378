/**
 * Created by kaushal on 24/04/17.
 */
function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie != '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = jQuery.trim(cookies[i]);
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) == (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}
csrftoken = getCookie('csrftoken');

// set X-CSRFtoken header to xhr request
$.ajaxSetup({ beforeSend: function(xhr) { xhr.setRequestHeader("X-CSRFToken", csrftoken); } });

// since, there is issue of setting fresh CSRF token with caching enabled,
// adding it via JS, to all post form in page.
$('form[method=post]').prepend('<input type="hidden" name="csrfmiddlewaretoken" value="'+csrftoken+'">');
